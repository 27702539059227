<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 사업장 -->
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-select
            :comboItems="useFlagItems"
            type="search"
            itemText="codeName"
            itemValue="code"
            label="LBLUSEFLAG"
            name="useFlag"
            v-model="searchParam.useFlag"
          ></c-select>
        </div>
      </template>
    </c-search-box>
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
      <c-table
        ref="grid"
        title="안전보건 목표 점검내용"
        :columns="grid.columns"
        :data="grid.data"
        :merge="grid.merge"
        :columnSetting="false"
        :isExcelDown="false"
        :isFullScreen="false"
        :filtering="false"
        :usePaging="false"
        @table-data-change="tableDataChange"
      >
        <!-- 버튼 영역 -->
        <template slot="table-button">
          <q-btn-group outline >
            <!--추가-->
            <c-btn v-if="editable" label="LBLADD" icon="add" @btnClicked="addMaster" />
            <!--저장-->
            <c-btn 
              v-if="editable" 
              :isSubmit="isSave"
              :url="saveUrl"
              :param="saveData"
              mappingType="PUT"
              label="LBLSAVE" 
              icon="save" 
              @beforeAction="saveMaster"
              @btnCallback="saveMasterCallback" />
            <!--검색-->
            <c-btn label="LBLSEARCH" icon="search" @btnClicked="getList" />
          </q-btn-group>
        </template>
        <template v-slot:customArea="{ props, col }">
          <template v-if="col.name === 'targetName'">
            <c-text-column
              :editable="editable"
              :col="col"
              :props="props"
              @datachange="datachange(props, col)"
            />
            <div v-if="editable">
              <q-btn-group outline class="ColumInnerBtnGroup">
                <q-btn
                  icon="add"
                  color="red-6"
                  text-color="white"
                  class="ColumInnerBtn"
                  align="center"
                  @click.prevent="innerBtnClicked(col, props)">
                  <q-tooltip>
                    점검내용 추가
                  </q-tooltip>
                </q-btn>
              </q-btn-group>
            </div>
          </template>
        </template>
      </c-table>
    </div>
  </div>
</template>

<script>
import { uid } from 'quasar';
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';

export default {
  name: 'safety-healthy-mst',

  data() {
    return {
      searchParam: {
        plantCd: null,
        useFlag: 'Y',
      },
      grid: {
        merge: [
          { index: 0, colName: 'saiSafetyHealthyId' },
          { index: 1, colName: 'saiSafetyHealthyId' },
        ],
        columns: [
          {
            required: true,
            name: 'plantCd',
            field: 'plantCd',
            //사업장
            label: 'LBLPLANT',
            align: 'center',
            style: 'width:100px',
            sortable: false,
            type: 'plant',
          },
          {
            required: true,
            name: 'targetName',
            field: 'targetName',
            label: '구분',
            align: 'left',
            style: 'width:130px',
            sortable: false,
            type: 'custom',
          },
          {
            required: true,
            name: 'detailName',
            field: 'detailName',
            label: '점검내용',
            align: 'left',
            style: 'width:300px',
            sortable: false,
            type: 'text',
          },
          {
            name: 'deptName',
            field: 'deptName',
            label: '해당팀',
            align: 'left',
            style: 'width:200px',
            sortable: false,
            type: 'deptMulti',
            deptCd: 'deptCd',
            isFirstValue: false,
          },
          {
            name: 'inspectionCycle',
            field: 'inspectionCycle',
            label: '점검주기',
            align: 'center',
            style: 'width:150px',
            sortable: false,
            type: 'text',
          },
          {
            name: 'managerName',
            field: 'managerName',
            label: '담당자',
            align: 'left',
            style: 'width:200px',
            type: 'text',
            sortable: false
          },
          {
            name: 'relatedEvidence',
            field: 'relatedEvidence',
            label: '관련근거',
            align: 'left',
            style: 'width:250px',
            type: 'text',
            sortable: false
          },
          {
            name: 'useFlag',
            field: 'useFlag',
            //사용여부
            label: 'LBLUSEFLAG',
            align: 'center',
            style: 'width:50px',
            type: 'check',
            true: 'Y',
            false: 'N',
            sortable: false,
          },
          {
            name: 'sortOrder',
            field: 'sortOrder',
            //정렬순서
            label: 'LBLSORTORDER',
            align: 'center',
            sortable: false,
            style: 'width:80px',
            type: 'number',
          },
        ],
        data: [],
      },
      useFlagItems: [
        { code: 'Y', codeName: '사용' },
        { code: 'N', codeName: '미사용' },
      ],
      isSave: false,
      editable: true,
      saveUrl: '',
      listUrl: '',
      saveData: [],
      shLabel: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.listUrl = selectConfig.sai.safetyHealthy.base.list.url;
      this.saveUrl = transactionConfig.sai.safetyHealthy.base.save.url;
      // code setting
      // list setting
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    datachange(props) {
      if (props.row['mstEditFlag'] !== 'C') {
        props.row['mstEditFlag'] = 'U'
        props.row['chgUserId'] = this.$store.getters.user.userId
      }
    },
    tableDataChange(props, col) {
      if (col.name !== 'gubunName' && props.row['itemEditFlag'] !== 'C') {
        props.row['itemEditFlag'] = 'U'
        props.row['chgUserId'] = this.$store.getters.user.userId
      }
    },
    addMaster() {
      this.grid.data.splice(0, 0, {
        saiSafetyHealthyId: uid(),  // 안전보건목표 일련번호
        plantCd: null,  // 사업장
        targetName: '',  // 안전보건목표
        useFlag: 'Y',  // 사용여부
        regUserId: this.$store.getters.user.userId,  // 등록자
        mstEditFlag: 'C',
        itemEditFlag: 'C'
      })
    },
    innerBtnClicked(col, props) {
      this.grid.data.splice(props.rowIndex, 0, {
        saiSafetyHealthyDetailId: uid(),  // 안전보건목표 세부추진사항 일련번호
        plantCd: props.row.plantCd,  // 사업장
        targetName: props.row.targetName,  // 안전보건목표
        saiSafetyHealthyId: props.row.saiSafetyHealthyId,  // 안전보건목표 일련번호
        detailName: '', // 세부추진사항
        inspectionCycle: '', // 점검주기
        relatedEvidence: '', // 관련근거
        deptCd: null,  // 해당부서
        managerName: '',  // 담당자
        // kpi: '',  // kpi 성과지표
        useFlag: 'Y',  // 사용여부
        regUserId: this.$store.getters.user.userId,  // 등록자
        itemEditFlag: 'C'
      })
    },
    saveMaster() {
      if (this.$comm.validTable(this.grid.columns, this.grid.data)) {
        /**
         * [안전보건목표 - 항목] 데이터 분리
         * 분리시에 수정&등록된 데이터만 처리
         */
        let saveData = this.grid.data.filter(item => {
          return item.mstEditFlag === 'C' || item.mstEditFlag === 'U' 
            || item.itemEditFlag === 'C' || item.itemEditFlag === 'U'
        });
        this.$set(this.$data, 'saveData', this.$_.map(this.$_.uniqBy(saveData, 'saiSafetyHealthyId'), (mst) => {
          return {
            saiSafetyHealthyId: mst.saiSafetyHealthyId,  // 법규등록부 구분 일련번호
            plantCd: mst.plantCd,
            targetName: mst.targetName,
            useFlag: 'Y',  // 사용여부
            editFlag: mst.mstEditFlag,
            regUserId: this.$store.getters.user.userId,
            chgUserId: this.$store.getters.user.userId,
            items: this.$_.map(this.$_.filter(saveData, { saiSafetyHealthyId: mst.saiSafetyHealthyId }), (item) => {
              return {
                saiSafetyHealthyId: mst.saiSafetyHealthyId,  // 법규등록부 구분 일련번호
                saiSafetyHealthyDetailId: item.saiSafetyHealthyDetailId,  // 법규등록부 항목 일련번호
                detailName: item.detailName,  // 세부추진사항
                inspectionCycle: item.inspectionCycle, // 점검주기
                relatedEvidence: item.relatedEvidence, // 관련근거
                deptCd: item.deptCd,  // 해당부서
                managerName: item.managerName,  // 담당자
                // kpi: item.kpi,  // kpi성과지표
                useFlag: item.useFlag,  // 사용여부
                sortOrder: item.sortOrder,
                editFlag: item.itemEditFlag,
                regUserId: this.$store.getters.user.userId,
                chgUserId: this.$store.getters.user.userId,
              }
            })
          }
        }));
        if(saveData.length > 0) {
          window.getApp.$emit('CONFIRM', {
            title: 'LBLCONFIRM',  // 확인
            message: 'MSGSAVE', // 저장하시겠습니까?   // 저장하시겠습니까?
            type: 'info',
            // 확인 callback 함수
            confirmCallback: () => {
              this.isSave = !this.isSave
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('ALERT', {
            title: '안내 ', /* 안내 */
            message: 'MSGNOCHANGEDATA', // 변경된 데이터가 없습니다., /* 변경된 데이터가 없습니다. */
            type: 'info', // success / info / warning / error
          });
        }
      }
    },
    saveMasterCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.getList();
    }
  }
};
</script>
